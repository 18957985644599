$font-size: 1rem;
$line-height: 1.4;
$lines-to-show-heading: 3;
$lines-to-show-text: 4;
$lines-to-show-author: 2;

.row {
  margin: 0;
  padding: 0;
  width: 100%;
}

video {
  border-radius: 20px;
}

.card-section {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 70px;
  padding-bottom: 20px;
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    margin-bottom: 30px;
  }
}

.home-page-section {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    margin-top: 30px;
  }
}

.overflow-sec {
  display: flex;
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    overflow-x: auto;
    white-space: nowrap;
  }
}

.overflow-m {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    width: 75% !important;
    min-width: 75%;
  }
}
.m-m-top {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    margin-top: 50px;
  }
}

.hp-video-frame {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    height: 100%;
  }
  top: 0px;
  position: absolute;
  width: 100%;
  height: 650px;
  right: 0px;
}
.hp-video {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    height: 100% !important;
  }
  padding: 10px;
  width: 100% !important;
  height: 650px !important;
}

.hp-button {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    margin-bottom: 30px;
    justify-content: center;
  }
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.p-sec-text {
  font-size: 1.5rem;
  font-weight: 300;
}

.p-sec-heading {
  line-height: 1.049979em;
  letter-spacing: -0.02em;
  font-size: 3rem;
  color: #191919;
  font-weight: 300;
}

.font-ar-text {
  font-family: "JannaLT", sans-serif !important;
}
.font-ar-heading {
  font-family: "KhebratMusamim", sans-serif !important;
}

.font-en-text {
  font-family: "AvenirLight", sans-serif !important;
}
.font-en-heading {
  font-family: "KhebratMusamim", sans-serif !important;
}
.link-to {
  outline: none !important;
  text-decoration: none !important;
  color: #000;
}
.hp-app-icons-section {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    justify-content: center;
  }
  .hp-app-icon-card {
    background: rgb(0, 0, 0);
    padding: 10px;
    border-radius: 10px;
    img {
      @media only screen and (min-width: 0px) and (max-width: 992px) {
        width: 100px;
        height: 25px;
      }
      width: 150px;
      height: 35px;
      object-fit: contain;
    }
  }
}
