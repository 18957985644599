.Header-page {
  .Header_searchIcon_rtl {
    position: absolute;
    left: 10px;
    z-index: 999;
    svg {
      font-size: 20px;
    }
  }
  .arabic-font-change {
    font-family: GessTwoMedium, sans-serif;
  }

  .Header_searchIcon_ltr {
    position: absolute;
    right: 10px;
    z-index: 999;
    svg {
      font-size: 20px;
    }
  }
  .Header-sub-nav-list {
    align-items: center;
  }
  .nav-list-b {
    display: none;
  }
  .nav-list-h {
    display: flex;
  }

  .join-buttom {
    color: #000 !important;
    background-color: #f7ee23 !important;
    border-color: #f7ee23 !important;
    border: none !important;
    padding: 0px 10px 0px 10px !important;
    display: flex;
    align-items: center;
  }
  .join-buttom:hover {
    color: #000 !important;
    background-color: #f7ee23b8 !important;
    border-color: #f7ee23b8 !important;
    padding: 0px 10px 0px 10px !important;
    display: flex;
    border: none !important;
    align-items: center;
  }

  .Header-menu-topnav-form {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      position: fixed !important;
      background: #fff !important;
      z-index: 111 !important;
      right: 10px !important;
      left: 10px !important;
      height: auto !important;
      width: auto !important;
    }
    font-size: 1rem;
    line-height: 0;
    box-shadow: none;
    background: #fff0;
    border: none;
    color: #444;
    position: relative;
    height: 64px;
    align-items: center;
    font-weight: 300;
    width: 450px;
    max-width: 100%;
    cursor: pointer;
    .Header-search-form {
      width: 100%;
      align-items: center;
      #search_query {
        border: 1px solid #0c364a38;
        border-radius: 4px;
        box-shadow: none;
        font-size: 18px;
        border-top: none;
        border-left: none;
        border-right: none;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  .Header-close-icon {
    font-size: 22px !important;
    border: none !important;
    padding: 0px;
    color: #0c364a;
    cursor: pointer;
  }
  .form-b {
    display: flex;
  }
  .form-h {
    display: none;
  }
  background: #f5f5f500;
  top: 0;
  right: 0;
  left: 0;
  white-space: nowrap;
  z-index: 999;
  position: -webkit-sticky;
  position: sticky;
  .Header-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media only screen and (min-width: 0px) and (max-width: 992px) {
        display: block;
        overflow: auto;
      }
    }
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      display: flex;
      align-items: center;
    }
  }
  .Header-menu2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f5f5f5;
    position: relative;
    border-top: 1px solid #eee;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      display: block;
    }
  }
  .ant-menu-horizontal {
    line-height: 0;
    box-shadow: none;
    background: #fff0;
    border: none;
    color: #eee;
  }
  .Header-menu-icon {
    font-size: 16px;
    line-height: 0;
    box-shadow: none;
    background: #fff0;
    border: none;
    color: #444;
    padding-right: 50px;
    cursor: pointer;
    position: relative;
    padding: 10px;
    height: 64px;
    display: flex;
    align-items: center;
  }

  .Header-menu-logoName {
    font-size: 20px;
    line-height: 0;
    box-shadow: none;
    background: #fff0;
    border: none;
    color: #222945;
    padding-right: 0px;
    cursor: pointer;
    position: relative;
    height: 64px;
    display: block;
    font-weight: 400;
    .scrolling-img {
      margin-top: 0px !important;
    }
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      font-size: 12px;
      display: flex;
      align-items: center;
      height: auto;
      font-weight: 700;
      width: 100%;
      text-align: left;
      .scrolling-img {
        width: 50px !important;
      }
      .scrolling-img-rev {
        width: 60px !important;
      }
    }
    a {
      color: #222945;
      letter-spacing: 1px;
      font-weight: 600;
      font-size: 28px;
    }
  }
  .Header-menu-topnav {
    font-size: 1rem;
    line-height: 0;
    box-shadow: none;
    background: #fff0;
    border: none;
    color: #444;
    position: relative;
    padding: 0px 20px 0px 20px;
    height: 64px;
    display: flex;
    align-items: center;
    font-weight: 300;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      font-size: 14px;
      height: auto;
      float: left;
      width: 33.3333333%;
      display: block;
    }
    a {
      color: #444;
    }
  }
  .Header-menu-topnav-card {
    display: flex;
    align-items: center;
    padding-left: 100px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      padding-left: 0px;
      justify-content: center;
    }
  }
  .Header-menu-list {
    display: flex;
    align-items: center;
    width: 100%;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      display: block;
      width: 100%;
      text-align: center;
    }
  }
  .Header-menu-list2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    .mobile-hide {
      display: none !important;
    }
  }

  .Header-menuhb {
    display: none;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      display: block;
    }
  }

  .Header-back {
    display: none;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      display: block;
    }
  }

  .Header-dreambets-logo {
    width: 100px;
  }
  .Header-waller-connect {
    background: #ed1c24;
    color: #12043d;
    padding: 7px 15px 7px 15px;
    border-radius: 2px;
    font-size: 13px;
    font-weight: 600;
    display: flex;
    align-items: center;
    height: 40px;
  }
  .Cart-value {
    position: absolute;
    font-size: 14px;
    right: -18px;
    background: #444;
    color: #eee;
    border-radius: 100%;
    width: 24px;
    height: 24px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -10px;
  }
  .Header-menu-account .Header-waller-connect1:last-child {
    padding: 7px 12px 7px 12px;
  }
  .Header-waller-connect1:hover {
    color: #fcfcfc;
  }
  .Header-waller-connect1 {
    display: block;
    text-decoration: none;
    padding: 7px 30px 7px 30px;
    border-radius: 2px;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    margin-left: 0px;
    height: 40px;
    position: relative;
    cursor: pointer;
    color: #fff;
    font-weight: 400;
    text-transform: capitalize;
    small {
      color: #fcfcfc;
      display: flex;
      align-items: center;
      font-size: 14px;
    }

    a {
      display: flex;
      align-items: center;
      position: relative;
    }
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      font-size: 18px;
      padding: 7px 10px 7px 10px;
    }
  }

  .show-menu-icon {
    color: #fcfcfc;
    display: none;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      display: block !important;
      margin-left: 10px;
    }
  }
  .hide-menu-icon {
    display: none;
    color: #fcfcfc;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    z-index: 111;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      display: block !important;
    }
  }

  .Header-waller-connect2 {
    background: #3d2b93;
    color: #eee;
    padding: 7px 15px 7px 15px;
    border-radius: 2px;
    font-size: 13px;
    font-weight: 600;
    display: flex;
    align-items: center;
    height: 40px;
  }
  .Header-menu-account {
    display: flex;
    align-items: center;
    // @media only screen and (min-width: 0px) and (max-width: 992px) {
    //   justify-content: center;
    //   padding-left: 0px;
    //   display: block;
    //   position: absolute;
    //   top: 0;
    //   right: 0;
    //   left: 0;
    //   width: 220px;
    //   height: 100vh;
    //   background: #000;
    //   padding: 20px;
    //   border-right: 1px solid #eeed3259;
    // }
  }

  @keyframes Header-menu-account {
    from {
      display: block;
    }
    to {
      transform: translateY(40px);
      opacity: 0;
    }
  }

  .Header-rate {
    padding-right: 50px;
    line-height: 1;
    color: #eee;
    text-align: right;
    font-size: 13px;
    font-weight: 600;
    display: flex;
    align-items: center;
    padding-left: 30px;
    .red {
      color: #ed1d24;
    }
    .green {
      color: #39a935;
    }
    img {
      width: 40px;
      padding-left: 10px;
    }
  }
  .Header-games {
    color: #eee;
    font-size: 17px;
    font-weight: 700;
    padding-left: 50px;
    display: flex;
    align-items: center;
  }
  .Header-challenge {
    color: #eee;
    font-size: 17px;
    font-weight: 700;
    padding-left: 50px;
    display: flex;
    align-items: center;
  }
  .Header-side-menu1,
  .Header-side-menu2,
  .Header-side-menu3,
  .Header-side-menu4,
  .Header-side-menu5,
  .Header-side-menu6,
  .Header-side-menu7,
  .Header-side-menu8,
  .Header-side-menu9 {
    color: #444;
    position: absolute;
    top: 64px;
    padding: 0px 40px 20px 40px;
    background: #fff;
    overflow: auto;
    z-index: 9999;
    width: 1180px;
    max-width: 100%;
    border: 1px solid #eee;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      color: #444;
      position: fixed;
      top: 128px;
      padding: 20px;
      background: #f5f5f5;
      overflow: auto;
      display: none;
      right: 0px;
      left: 0px;
      z-index: 9999;
      width: 60% !important;
      margin: 0;
      height: 100%;
      overflow: auto;
    }
  }

  .Header-side-menu-item {
    color: #444;
    font-size: 1rem;
    display: block;
    width: 25%;
    padding: 0px 20px 0px 10px;
    margin-bottom: 1.2rem;
    white-space: normal;
    a {
      color: #444;
    }
  }
  .Header-side-menu-item-0 {
    color: #444;
    font-size: 1rem;
    display: block;
    width: 25%;
    padding: 0px 20px 0px 10px;
    margin-bottom: 1.2rem;
    a {
      color: #444;
    }
  }
  .Header-menu-close-button {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 111;
  }

  .Header-menu-icon:hover .Header-side-menu {
    display: block;
  }
  .Header-TMM {
    display: flex;
    flex-wrap: wrap;
    padding: 40px 0px 40px 0px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      display: block;
    }
    .Header-TMM-C {
      padding: 10px;
    }
  }
}
.Header-dropdown-menu {
  padding: 5px !important;
  margin-top: 9px;
  li {
    color: #444;
    font-size: 1rem !important;
  }
  li:hover {
    background: #fff0;
    font-size: 1rem !important;
  }
}
a:hover {
  text-decoration: none !important;
}
.Header-singin-header {
  text-align: center;
  padding: 15px;
  position: relative;
  h3 {
    font-weight: 600;
  }
  h5 {
    font-weight: 500;
  }
}
.Header-Signin-dialogue {
  .Header-close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 111;
    cursor: pointer;
  }
  .modal-body {
    padding: 1.5rem !important;
  }
  .Header-close-modal-icon {
    font-size: 28px;
  }

  input {
    border-radius: 4;
    height: 45px;
  }
  input:focus {
    outline: none !important;
    box-shadow: none !important;
    text-decoration: none !important;
    text-transform: none !important;
    border-radius: 4;
  }
  label:focus {
    color: rgb(131, 176, 212);
  }
  .Header-signin-button {
    button {
      width: 100%;
    }
  }
  .Header-successMessage {
    color: #13aa66;
  }
  .Header-errorMessage {
    color: #e25151;
    font-size: 13px;
  }
}
.Header-account-dropdown {
  position: absolute;
  top: 51px;
  width: 200px;
  background: #fff;
  padding: 10px;
  right: 0;
  z-index: 999;
  border-radius: 4px;
  border: 1px solid #eee;
  a {
    font-size: 16px;
    color: #444;
    padding: 8px 10px 8px 10px;
  }
}

.blockAccountModal {
  display: block;
}
.hideAccountModal {
  display: none;
}
.blockAccountModalf {
  display: block;
}
.hideAccountModalf {
  display: none;
}
.click-account {
  cursor: pointer;
}

.styleList {
  bottom: 100%;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent rgb(255, 255, 255);
  border-image: initial;
  content: "";
  height: 0px;
  width: 0px;
  position: absolute;
  pointer-events: none;
  right: 12px;
}
.Header-menu-middle {
  position: relative;
  margin-right: 10px;
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    padding-top: 11px;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  form {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      width: 100%;
    }
  }
  input {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      width: 100%;
    }
    border: 1px solid #eee;
    border-radius: 0;
    width: 340px;
    max-width: 100%;
  }
}
.Header-menu-middle input:focus {
  border: 1px solid #d6d6d6;
  box-shadow: none !important;
}
.Header-search-icon:focus {
  border: none !important;
  box-shadow: none !important;
}
.Header-search-icon {
  font-size: 22px !important;
  border: none !important;
  padding: 0px;
  color: #0c364a;
  font-size: 1.5rem;
  cursor: pointer;
  svg {
    color: #0c364a;
  }
}

.expand-style {
  margin-top: 2px;
  color: #3f3f3f;
}

.cookies-modal-card {
  padding: 30px;
  .heading {
    font-weight: 600;
    font-size: 1.5rem;
    padding-bottom: 15px;
  }
  .text {
    font-size: 15px;
  }
  Button {
    width: 100%;
  }
}
.Header-trial-button {
  small {
    color: #fcfcfc;
    display: flex;
    align-items: center;
    font-size: 14px;
    justify-content: center;
  }
}

.dropdown-item:hover {
  color: red !important;
}
.dropdown-item:hover {
  background-color: #fff0 !important;
}

.Header-dropdown-menu {
  background: #f5f5f5 !important;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 2rem;
}
.swiper-button-next,
.swiper-button-prev {
  z-index: 111;
}

 

.ant-input-search-button {
  display: flex;
  align-items: center;
}

.language-dropdown {
  padding: 0.25rem 0.5rem !important;
  font-size: 1.1rem !important;
  cursor: pointer;
}

.HomeBanner-Text {
  // @media only screen and (min-width: 0px) and (max-width: 1240px) {
  //   position: relative;
  // }
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    display: flex;
    align-items: center;
    text-align: center;
    width: 100% !important;
    padding: 0 !important;
    .card-text1 {
      div {
        font-size: 20px !important;
      }
      h1 {
        font-size: 20px !important;
      }
      p {
        font-size: 20px !important;
      }
    }
  }

  position: absolute;
  right: 0px;
  left: 0px;
  top: 0px;
  display: flex;
  align-items: center;
  height: 100%;
}

.HomeBanner-Text-Card1 {
  width: 50%;
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    width: 0% !important;
  }
}
.HomeBanner-Text-Card2 {
  width: 50%;
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    width: 100% !important;
  }
}

.web-header-ad {
  color: #000;
  background: #f7ee23;
  display: flex;
  justify-content: center;
  padding: 10px;
  font-size: 14px;
  white-space: normal;
  text-align: center;
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    font-size: 10px;
  }
}

.row {
  width: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.mobile-menu-item-size {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    font-size: 12px !important;
    padding-left: 5px;
  }
}
.modal-header {
  .close {
    margin: 0 !important;
    padding: 0 !important;
  }
}

.grecaptcha-badge {
  visibility: hidden !important;
}
