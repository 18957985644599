.cp-phone-section {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    margin-bottom: 20px;
  }
}

.statisc-page {
  padding-top: 1rem;
  .static-container-card {
    padding: 20px;
    background: #fcfcfc;
  }
  .terms-text {
    font-size: 15px;
    color: #727272;
  }
  li {
    font-size: 15px;
    color: #727272;
  }
  h5 {
    font-size: 0.9rem;
    font-weight: 500;
  }
  h4 {
    font-size: 1rem;
    font-weight: 500;
  }
  h3 {
    font-weight: 500;
    font-size: 2rem;
  }
  p {
    font-size: 15px;
    color: #696f75;
  }
  .static-container {
    padding: 0 !important;
  }
  .static-row {
    justify-content: center;
  }
}

.PhoneInput {
  display: flex;
  align-items: center;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.PhoneInputCountry {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  // margin-right: var(--PhoneInputCountrySelect-marginRight);
  background-color: #fff !important;
  background-clip: padding-box;
  padding: 0px 10px;
  margin: 0px !important;
}

.PhoneInput input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: none !important;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.PhoneInputInput {
  flex: 1 1;
  min-width: 0;
  outline: none;
}

.PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
  word-wrap: normal;
}

.PhoneInputCountryIcon--border {
  box-shadow: none !important;
}

.PhoneInputCountryIcon {
  width: calc(1em * 1.5);
  height: 1em;
}

.PhoneInputCountryIconImg {
  display: block;
  width: 30px;
}

.PhoneInputCountrySelectArrow {
  display: block;

  content: "";
  width: 0.3em;
  height: 0.3em;
  margin-left: 10px;
  border-style: solid;
  border-color: var(--PhoneInputCountrySelectArrow-color);
  border-top-width: 0;
  border-bottom-width: 1px;
  border-left-width: 0;
  border-right-width: 1px;
  transform: rotate(45deg);
  opacity: 0.45;
}

.form-group-label {
  margin-bottom: 10px;
  label {
    font-size: 14px;
    margin-bottom: 2px;
    color: #696b6c;
    font-weight: 400;
  }
  input {
    height: 40px;
  }
  select {
    height: 40px;
  }
}
.form-group-check {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  label {
    font-size: 15px;
    margin: 5px 10px;
    cursor: pointer;
    color: #696b6c;
  }
  input {
    width: 15px;
    height: 15px;
    cursor: pointer;
  }
}

.pf-break {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  position: relative;
}
