.Footer-page {
  .Footer-menu-card {
    display: flex;
    color: #eee;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      display: block;
      text-align: center;
    }
  }
  .mobie-hide{
    @media only screen and (min-width: 0px) and (max-width: 992px) {
    display: none;
    }
  }

  .footer-icons {
    background: #fcfcfc4a;
    border-radius: 100px;
    padding: 4px;
    color: #f7ee23;
    font-size: 32px;
  }

  .Footer-menu-card1 {
    background: #644b9f;
    white-space: normal;
    overflow: auto;
    padding: 40px 0px 20px 0px;
    border-top: 1px solid #dee7ed;
  }
  .Footer-menu-card01 {
    background: #3d2b93;
  }
  .Footer-menu-card01 {
    display: flex;
    align-items: center;
    color: #eee;
    justify-content: space-around;
    padding: 20px;
    white-space: normal;
    overflow: auto;
  }
  .Footer-menu-item {
    font-size: 16px;
    font-weight: 500;
    h5 {
      font-size: 1rem;
      color: #f7ee23;
    }
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      padding-bottom: 20px;
    }
  }
  .Footer-menu-SubItem {
    padding: 5px 5px 5px 5px;
    font-weight: 300;
    font-size: 15px;
    display: flex;
    align-items: center;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      display: inline;
      padding: 10px;
      justify-content: center !important;
    }
    img {
      padding-bottom: 20px;
    }
    a {
      color: #fcfcfc;
    }
  }
  .Footer-menu-socia-SubItem {
    padding: 5px 5px 5px 5px;
    font-weight: 300;
    img {
      padding-bottom: 20px;
    }
    a {
      color: #444;
      display: flex;
      align-items: center;
      font-size: 28px;
      padding-right: 15px;
    }
  }
  .Footer-menu-social {
    display: flex;
    align-items: center;
    padding-top: 15px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      justify-content: center;
    }
  }
  .Footer-cp {
    text-align: right;
    color: #8e8e8e;
    font-size: 12px;
  }
}
.Footer-menu-bottom {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  @media only screen and (min-width: 0px) and (max-width: 992px) {
    display: block;
  }
  .Footer-menu-SubItem {
    text-decoration: none !important;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      width: 100%;
      display: block;
    }
    font-size: 14px;
    padding-right: 20px;
  }
  // .Footer-menu-SubItem:last-child {
  //   padding-right: 0 !important;
  // }
}

.fm-m{
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    display: block !important;
  }
}



@media only screen and (min-width: 0px) and (max-width: 992px) {
  .mdb{
display: block !important;
  }
}


