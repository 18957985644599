h1, h2 {
  color: #555;
  font-size: 2rem;
}
h1 {
  text-align: start;
  margin-bottom: 20px;
}
h2 {
  border-bottom: 0.5px solid #ddd;
  padding-bottom: 5px;
  font-size: 1.2rem;
}
p {
  margin-bottom: 15px;
}
 
.last-updated {
  text-align: start;
  font-size: 0.9em;
  color: #888;
}
a {
  color: #007BFF;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}